import React, { useState, useEffect, useRef } from "react"
import {
  Form,
  Input,
  Row,
  Col,
  Collapse,
  Spin,
  Button,
  Alert,
  Tag,
  Popconfirm,
  Table,
  Result,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import Sounds from "utils/sounds"
import * as moment from "moment"
import { AiOutlineDelete } from "react-icons/ai"
import { SearchOutlined, ClearOutlined } from "@ant-design/icons"
import { useLocation } from "react-router-dom"
const { Panel } = Collapse
import "toastr/build/toastr.min.css"

const statusPackage = [
  {
    id: 10,
    name: "Paket Hazır",
  },
  {
    id: 20,
    name: "Paket Gönderildi",
  },
  {
    id: 30,
    name: "Paket Teslim Alındı",
  },
]
const statusProduct = [
  {
    id: 10,
    name: "Ürün Hazır",
  },
  {
    id: 20,
    name: "Ürün Gönderildi",
  },
  {
    id: 30,
    name: "Ürün Teslim Alındı",
  },
  {
    id: 40,
    name: "Ürün Çıkarıldı",
  },
]
const PackageList = () => {
  document.title = "Create and send package | EASY JET CONNECT"
  const inputRef = useRef(null)
  const [form] = Form.useForm()
  const [refForm] = Form.useForm()
  const [formDetail] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [hiddenControl, sethiddenControl] = useState(false)
  const [tableDataDetailShow, setTableDataDetailShow] = useState(false)
  const [tableData, setTableData] = useState([])
  const [detail, setDetail] = useState(null)
  const [tableDataDetail, setTableDataDetail] = useState([])
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const productBarcode = params.get("productBarcode")
  const packageBackode = params.get("packageBarcode")
  const [cost, setCost] = useState(0)
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  const [tableParamsDetail, setTableParamsDetail] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const getCost = async () => {
    try {
      const res = await Http.get("api/packages/getCustomerPackagePriceDetail")
      if (res.success) {
        setCost(res.data?.price)
      } else {
        setCost(0)
      }
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    cost === 0 && getCost()
  }, [cost])

  useEffect(() => {
    cost > 0 && getData(null)
  }, [cost])

  const getData = (pagination, packageBackode) => {
    setTableDataDetail([])
    setTableDataDetailShow(false)
    const formValues = form.getFieldValue()
    setLoading(true)
    Http.get(
      `api/packages/GetCustomerPackages?PageNumber=${
        pagination?.current ? pagination.current : 1
      }&Limit=10${
        formValues.barcode
          ? "&search=" + formValues.barcode
          : packageBackode
          ? "&search=" + packageBackode
          : ""
      }`
    )
      .then(res => {
        if (res?.data?.data?.packages?.length > 0) {
          setTableData(res?.data)
          setTableParams({
            current: pagination?.current ? pagination.current : 1,
            pageSize: pagination?.pageSize ? pagination.pageSize : 10,
            total: res?.data?.totalItems,
          })
          if (productBarcode && res?.data?.data?.packages?.length === 1) {
            getDataDetail(
              { current: 1, pageSize: 10 },
              { packageId: res?.data?.data?.packages[0].packageId },
              productBarcode
            )
            formDetail.setFieldsValue({ barcode: productBarcode })
          } else if (res?.data?.data?.packages?.length === 1) {
            setDetail(res?.data?.data?.packages[0])
            getDataDetail({ current: 1, pageSize: 10 })
          }
          setLoading(false)
        } else {
          setTableData([])
          setTableParams({
            current: 1,
            pageSize: 10,
            total: 0,
          })
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (cost > 0) {
      if (packageBackode) {
        form.setFieldsValue({
          barcode: packageBackode,
        })
        getData(
          {
            current: 1,
            pageSize: 10,
          },
          packageBackode
        )
      } else {
        getData({
          current: 1,
          pageSize: 10,
        })
      }
    }
  }, [cost])

  const handleTableChange = pagination => {
    setLoading(true)
    setTableParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParams.total,
    })
    if (
      tableParams.current !== pagination.current ||
      tableParams.pageSize !== pagination.pageSize
    ) {
      getData({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    }
  }

  const getDataDetail = (pagination, row, productBarcode) => {
    setLoading(true)
    const values = formDetail.getFieldValue()
    Http.get(
      `api/packages/GetCustomerPackageProducts?PageNumber=${
        pagination?.current ? pagination.current : 1
      }&Limit=10&PackageId=${
        row?.packageId ? row.packageId : detail.packageId
      }${
        values.barcode
          ? "&search=" + values.barcode
          : productBarcode
          ? "&search=" + productBarcode
          : ""
      }`
    )
      .then(res => {
        if (res?.data?.data?.length > 0) {
          setTableDataDetail(res?.data)
          setTableDataDetailShow(true)
          setTableParamsDetail({
            current: pagination?.current ? pagination.current : 1,
            pageSize: pagination?.pageSize ? pagination.pageSize : 10,
            total: res?.data?.totalItems,
          })
          setLoading(false)
        } else {
          setLoading(false)
          setTableDataDetail([])
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  const handleTableChangeDetail = pagination => {
    setLoading(true)
    setTableParamsDetail({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParamsDetail.total,
    })
    if (
      tableParamsDetail.current !== pagination.current ||
      tableParamsDetail.pageSize !== pagination.pageSize
    ) {
      getDataDetail({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    }
  }

  const deletePackage = id => {
    setLoading(true)
    Http.post("api/packages/DeleteCustomerPackageProduct", {
      packageProductId: id,
    })
      .then(res => {
        if (res.success) {
          Sounds.success()
          setLoading(false)
          formDetail.resetFields()
          getData()
          getDataDetail(tableParamsDetail)
        } else {
          setLoading(false)
          Sounds.error()
        }
      })
      .catch(function (error) {
        setLoading(false)
        Sounds.error()
      })
  }

  const columns = [
    {
      title: "Package Barcode",
      dataIndex: "barcode",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (_, row) => {
        return moment(new Date(row.createdDate)).format("DD.MM.YYYY")
      },
    },
    {
      title: "Products Count",
      dataIndex: "productCount",
      width: 100,
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      dataIndex: "totalPrice",
      width: 150,
      render: (_, row) => {
        return row.totalPrice ? row.totalPrice.toFixed(2) + " $" : ""
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, row) => {
        return (
          <Tag
            style={{ width: "100%", textAlign: "center", padding: 4 }}
            color={
              row.status === 10
                ? "lime"
                : row.status === 20
                ? "orange"
                : row.status === 30
                ? "cyan"
                : "magenta"
            }
          >
            {statusPackage.find(x => x.id === row.status)
              ? statusPackage.find(x => x.id === row.status).name
              : "Paket Hazır"}
          </Tag>
        )
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 250,
      render: (_, row) => {
        if (row.status === 10) {
          return (
            <div
              style={{
                display: "flex",
                align: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  formDetail.resetFields()
                  sethiddenControl(row.status !== 10)
                  setDetail(row)
                  getDataDetail(
                    {
                      current: 1,
                      pageSize: 10,
                    },
                    row
                  )
                }}
              >
                Detail
              </Button>
              <Popconfirm
                title="Do you confirm the sending of the package?"
                description="Are you sure you want to send this package?"
                onConfirm={() => sendPackage(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary" className="send_btn">
                  Send Package
                </Button>
              </Popconfirm>
            </div>
          )
        } else {
          return (
            <Button
              type="primary"
              onClick={() => {
                formDetail.resetFields()
                sethiddenControl(row.status !== 10)
                setDetail(row)
                getDataDetail(
                  {
                    current: 1,
                    pageSize: 10,
                  },
                  row
                )
              }}
            >
              Detail
            </Button>
          )
        }
      },
    },
  ]

  const columnsDetail = [
    {
      title: "Product Barcode",
      dataIndex: "barcode",
      width: 200,
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      width: 200,
      render: (_, row) => {
        return moment(new Date(row.createdDate)).format("DD.MM.YYYY")
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      render: (_, row) => {
        return (
          <Tag
            style={{ width: "100%", textAlign: "center", padding: 4 }}
            color={
              row.status === 10
                ? "lime"
                : row.status === 20
                ? "orange"
                : row.status === 30
                ? "cyan"
                : "magenta"
            }
          >
            {statusProduct.find(x => x.id === row.status)
              ? statusProduct.find(x => x.id === row.status).name
              : ""}
          </Tag>
        )
      },
    },
    {
      title: "",
      dataIndex: "action",
      align: "center",
      hidden: hiddenControl,
      render: (_, row) => {
        return (
          <Popconfirm
            title="Delete this package"
            description="Are you sure to delete this package?"
            onConfirm={() => deletePackage(row.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              danger
              size="small"
              type="default"
              disabled={row.status === 10 ? false : true}
              icon={<AiOutlineDelete style={{ marginTop: "-1px" }} />}
            >
              Delete
            </Button>
          </Popconfirm>
        )
      },
    },
  ].filter(item => !item.hidden)

  let locale = {
    emptyText: (
      <Result
        title="No data"
        subTitle="You have not performed any pectation process yet."
      />
    ),
  }

  const sendPackage = values => {
    Http.post("api/packages/UpdateCustomerPackageStatus", {
      packageId: values.packageId,
    })
      .then(res => {
        if (res.success) {
          formDetail.resetFields()
          Sounds.success()
          getData(tableParams)
        } else {
          setLoading(false)
          Sounds.error()
        }
      })
      .catch(function (error) {
        setLoading(false)
        Sounds.error()
      })
  }

  const savePackageByProduct = () => {
    const { barcodePackage, barcodeProduct } = refForm.getFieldsValue()
    let obj = [
      {
        barcode: barcodePackage.trim(),
        children: [{ barcode: barcodeProduct.trim() }],
      },
    ]
    Http.post("api/packages/addPackages", obj)
      .then(res => {
        if (res.success) {
          toastr.success(
            `${barcodePackage} barkodlu pakete ${barcodeProduct} barkodlu ürün eklendi..`
          )
          form.setFieldsValue({
            barcode: barcodePackage.trim(),
          })
          refForm.setFieldsValue({
            barcodeProduct: "",
          })
          Sounds.success()
          getData()
          setTimeout(() => {
            inputRef.current && inputRef.current.focus()
            setLoading(false)
          }, 600)
        } else {
          toastr.error(res.Message)
          setLoading(false)
          refForm.setFieldsValue({
            barcodeProduct: "",
          })
          Sounds.error()
          setTimeout(() => {
            inputRef.current && inputRef.current.focus()
          }, 600)
        }
      })
      .catch(function (error) {
        Sounds.error()
        toastr.error(`Process faild. ${error}`)
        setLoading(false)
      })
  }
  return (
    <React.Fragment>
      <div className="page-content mb-3">
        {cost > 0 ? (
          <Spin spinning={loading}>
            <Row gutter={[25, 25]}>
              <Col lg={14}>
                <Col lg={24}>
                  <Collapse activeKey={["1"]} expandIcon={() => false}>
                    <Panel
                      key="1"
                      header={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          Packaging
                        </div>
                      }
                    >
                      <Form form={refForm} layout="vertical">
                        <Row gutter={[25, 25]}>
                          <Col lg={12}>
                            <Form.Item
                              label={
                                <>
                                  Package Barcode
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "red",
                                      marginLeft: "10px",
                                      opacity: "0.6",
                                      marginTop: 5,
                                    }}
                                  >
                                    * Please press enter!
                                  </span>
                                </>
                              }
                              name="barcodePackage"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required!",
                                },
                              ]}
                            >
                              <Input allowClear disabled={loading} />
                            </Form.Item>
                          </Col>
                          <Col lg={12}>
                            <Form.Item
                              label={
                                <>
                                  Product Delivery Barcode
                                  <span
                                    style={{
                                      fontSize: "10px",
                                      color: "red",
                                      marginLeft: "10px",
                                      opacity: "0.6",
                                      marginTop: 5,
                                    }}
                                  >
                                    * Please press enter!
                                  </span>
                                </>
                              }
                              name="barcodeProduct"
                              rules={[
                                {
                                  required: true,
                                  message: "This field is required!",
                                },
                              ]}
                            >
                              <Input
                                allowClear
                                ref={inputRef}
                                onPressEnter={e => {
                                  if (e.target.value.length > 0) {
                                    setLoading(true)
                                    form
                                      .validateFields()
                                      .then(() => {
                                        savePackageByProduct()
                                      })
                                      .catch(err => {
                                        setLoading(false)
                                        toastr.error(
                                          "Please fill in the required fields"
                                        )
                                        Sounds.warning()
                                      })
                                  }
                                }}
                                disabled={loading}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </Col>
                <Col lg={24}>
                  <Collapse
                    activeKey={["1"]}
                    expandIcon={() => false}
                    className="mt-4"
                  >
                    <Panel
                      key="1"
                      header={
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Package List</div>

                          <div>* Total: {tableData?.totalItems}</div>
                        </div>
                      }
                    >
                      <Form form={form} layout="vertical">
                        <Row gutter={[16, 16]}>
                          <Col lg={12}>
                            <Form.Item label="Barcode" name="barcode">
                              <Input
                                onPressEnter={() => getData(null)}
                                allowClear
                              />
                            </Form.Item>
                          </Col>

                          <Col
                            span={6}
                            className="d-flex align-items-center justify-content-start gap-2"
                          >
                            <Button
                              type="button"
                              className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                              icon={<SearchOutlined />}
                              onClick={() => {
                                getData(null)
                              }}
                            >
                              Search
                            </Button>
                            <Button
                              type="button"
                              className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                              icon={<ClearOutlined />}
                              onClick={() => {
                                formDetail.resetFields()

                                form.setFieldsValue({
                                  barcode: "",
                                })
                                getData(null)
                              }}
                            >
                              Clear
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                      <Table
                        bordered
                        size="small"
                        columns={columns}
                        dataSource={tableData?.data?.packages || []}
                        locale={locale}
                        rowKey={record => record.id}
                        pagination={tableParams}
                        loading={loading}
                        onChange={handleTableChange}
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Col>
              {tableDataDetailShow && (
                <Col lg={10}>
                  <Col lg={24}>
                    <Collapse activeKey={["1"]} expandIcon={() => false}>
                      <Panel
                        key="1"
                        header={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>{detail?.barcode} Detail</div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                                columnGap: 10,
                              }}
                            >
                              {" "}
                              <div> * Total: {tableDataDetail?.totalItems}</div>
                              <div>
                                * Total Price:{" "}
                                {tableDataDetail?.totalItems * cost} ${" "}
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <Form form={formDetail} layout="vertical">
                          <Row gutter={[16, 16]}>
                            <Col lg={12}>
                              <Form.Item label="Product Barcode" name="barcode">
                                <Input
                                  onPressEnter={() =>
                                    getDataDetail({
                                      current: 1,
                                      pageSize: 10,
                                    })
                                  }
                                  allowClear
                                />
                              </Form.Item>
                            </Col>

                            <Col
                              span={8}
                              className="d-flex align-items-center justify-content-start gap-2"
                            >
                              <Button
                                type="button"
                                className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                                icon={<ClearOutlined />}
                                onClick={() => {
                                  formDetail.setFieldsValue({
                                    barcode: "",
                                  })
                                  getDataDetail({
                                    current: 1,
                                    pageSize: 10,
                                  })
                                }}
                              >
                                Clear
                              </Button>
                              <Button
                                type="button"
                                className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                                icon={<SearchOutlined />}
                                onClick={() => {
                                  getDataDetail({
                                    current: 1,
                                    pageSize: 10,
                                  })
                                }}
                              >
                                Search
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                        <Table
                          bordered
                          size="small"
                          columns={columnsDetail}
                          dataSource={tableDataDetail?.data || []}
                          locale={locale}
                          loading={loading}
                          rowKey={record => record.id}
                          pagination={tableParamsDetail}
                          onChange={handleTableChangeDetail}
                        />
                      </Panel>
                    </Collapse>
                  </Col>
                </Col>
              )}
            </Row>
          </Spin>
        ) : (
          <Alert
            message="Sistem Mesajı!"
            description="Paketleme işlemleriniz için sistem admin tarafından size ait ürün baz fiyatını belirlemesi gerekemektedir. İşlem yapmak için iletişime geçiniz!"
            type="error"
          />
        )}
      </div>
    </React.Fragment>
  )
}

export default PackageList
