import React, { useState, useEffect } from "react"
import { FormFeedback, Label } from "reactstrap"
import {
  Card,
  Spin,
  Form,
  Button,
  Input,
  Col,
  Select,
  Row,
  Tag,
  Popconfirm,
  Divider,
} from "antd"
import * as Http from "utils/http.helper"
import toastr from "toastr"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumbs from "components/Common/Breadcrumb"
import "toastr/build/toastr.min.css"
// Black t-shirt
import teeFrontBlack from "assets/images/transparent/TEE/TEE-B-FRONT.png"
import teeBackBlack from "assets/images/transparent/TEE/TEE-B-BACK.png"
import teeLeftBlack from "assets/images/transparent/TEE/TEE-B-LEFT.png"
import teeRightBlack from "assets/images/transparent/TEE/TEE-B-RIGHT.png"
// White t-shirt
import teeFrontWhite from "assets/images/transparent/TEE/TEE-W-FRONT.png"
import teeBackWhite from "assets/images/transparent/TEE/TEE-W-BACK.png"
import teeLeftWhite from "assets/images/transparent/TEE/TEE-W-LEFT.png"
import teeRightWhite from "assets/images/transparent/TEE/TEE-W-RIGHT.png"
// Black Onesie
import onesieFrontBlack from "assets/images/transparent/ONESIE/ONESIE-B-FRONT.png"
import onesieBackBlack from "assets/images/transparent/ONESIE/ONESIE-B-BACK.png"
// White Onesie
import onesieFrontwhite from "assets/images/transparent/ONESIE/ONESIE-W-FRONT.png"
import onesieBackwhite from "assets/images/transparent/ONESIE/ONESIE-W-BACK.png"
// Black Sweatshirt
import sweatshirtFrontBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-FRONT.png"
import sweatshirtBackBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-BACK.png"
import sweatshirtLeftBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-LEFT.png"
import sweatshirtRightBlack from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-B-RIGHT.png"
// White Sweatshirt
import sweatshirtFrontWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-FRONT.png"
import sweatshirtBackWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-BACK.png"
import sweatshirtLeftWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-LEFT.png"
import sweatshirtRightWhite from "assets/images/transparent/SWEATSHIRT/SWEATSHIRT-W-RIGHT.png"
// Black Hoodie
import hoodieFrontBlack from "assets/images/transparent/HOODIE/HOODIE-B-FRONT.png"
import hoodieBackBlack from "assets/images/transparent/HOODIE/HOODIE-B-BACK.png"
import hoodieLeftBlack from "assets/images/transparent/HOODIE/HOODIE-B-LEFT.png"
import hoodieRightBlack from "assets/images/transparent/HOODIE/HOODIE-B-RIGHT.png"
// White Hoodie
import hoodieFrontWhite from "assets/images/transparent/HOODIE/HOODIE-W-FRONT.png"
import hoodieBackWhite from "assets/images/transparent/HOODIE/HOODIE-W-BACK.png"
import hoodieLeftWhite from "assets/images/transparent/HOODIE/HOODIE-W-LEFT.png"
import hoodieRightWhite from "assets/images/transparent/HOODIE/HOODIE-W-RIGHT.png"

import ImageComponent from "components/image"
const { TextArea } = Input
import "./style.scss"

const optionGroup = [
  {
    value: "front",
    label: "Front View",
  },
  {
    value: "left_front",
    label: "Left and Upper Front View",
  },
  {
    value: "rigth_front",
    label: "Right and Upper Front View",
  },
  {
    value: "left",
    label: "Left Side View",
  },
  {
    value: "right",
    label: "Right Side View",
  },
  {
    value: "back",
    label: "Back View",
  },
]
const optionGroupOnesie = [
  {
    value: "front",
    label: "Front View",
  },
  {
    value: "back",
    label: "Back View",
  },
]
const optionGroupSweat = [
  {
    value: "front",
    label: "Front View",
  },
  {
    value: "left_front",
    label: "Left and Upper Front View",
  },
  {
    value: "rigth_front",
    label: "Right and Upper Front View",
  },
  {
    value: "left",
    label: "Left Side View",
  },
  {
    value: "right",
    label: "Right Side View",
  },
  {
    value: "back",
    label: "Back View",
  },
  {
    value: "inside_right_sleeve",
    label: "Inside Right Sleeve",
  },
  {
    value: "inside_left_sleeve",
    label: "Inside Left Sleeve",
  },
  {
    value: "outside_right_sleeve",
    label: "Outside Right Sleeve",
  },
  {
    value: "outside_left_sleeve",
    label: "Outside Left Sleeve",
  },
]
const imageValue = [
  {
    position: "front",
    img: 0,
  },
  {
    position: "left_front",
    img: 0,
  },
  {
    position: "rigth_front",
    img: 0,
  },
  {
    position: "left",
    img: 0,
  },
  {
    position: "right",
    img: 0,
  },
  {
    position: "back",
    img: 0,
  },
  {
    position: "inside_right_sleeve",
    img: 0,
  },
  {
    position: "inside_left_sleeve",
    img: 0,
  },
  {
    position: "outside_right_sleeve",
    img: 0,
  },
  {
    position: "outside_left_sleeve",
    img: 0,
  },
]

const ApparelDesignUpload = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  const [selectedFiles, setselectedFiles] = useState([])
  const [color, setColor] = useState(null)
  const [controlArea, setControlArea] = useState(true)
  const [selectedMulti, setselectedMulti] = useState([])
  const [erroList, setErroList] = useState([])
  const [style, setStyle] = useState(null)
  const [options, setOptions] = useState({ sizes: [], colors: [], styles: [] })
  const [registeredPositions, setRegisterePositions] = useState([])
  const [loading, setLoading] = useState(false)
  const [boxLoading, setBoxLoading] = useState(false)

  useEffect(() => {
    if (style) {
      if (style.label === "Sweatshirt") {
        setControlArea(
          optionGroupSweat.filter(x => !registeredPositions.includes(x.value))
            .length > 0
        )
      } else if (style?.label === "Onesie") {
        setControlArea(
          optionGroupOnesie.filter(x => !registeredPositions.includes(x.value))
            .length > 0
        )
      } else {
        setControlArea(
          optionGroup.filter(x => !registeredPositions.includes(x.value))
            .length > 0
        )
      }
    }
  }, [style])

  const getOptions = () => {
    setLoading(true)
    Http.get(`api/orders/GetApparelProperties`)
      .then(res => {
        if (res?.success) {
          setLoading(false)
          setOptions({
            sizes: [
              {
                label: "Size",
                options: res.data.sizes.map(item => {
                  return { label: item.name, value: item.id }
                }),
              },
            ],
            colors: [
              {
                label: "Color",
                options: res.data.colors.map(item => {
                  return {
                    label: item.name + " (" + item.hexCode + ")",
                    value: item.id,
                    hexCode: item.hexCode,
                  }
                }),
              },
            ],
            styles: [
              {
                label: "Styles",
                options: res.data.styles.map(item => {
                  return {
                    label: item.name,
                    value: item.id,
                  }
                }),
              },
            ],
          })
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error.response.data.Message)
      })
  }

  useEffect(() => {
    getOptions()
  }, [])

  const getImages = () => {
    setLoading(true)
    Http.get(`api/orders/GetApparelOrderImages?OrderId=${state?.orderId}`)
      .then(res => {
        if (res?.success) {
          setRegisterePositions(
            res.data.map(item => {
              return item.positionCode
            })
          )
          setLoading(false)
        }
      })
      .catch(function (error) {
        setLoading(false)
      })
  }

  useEffect(() => {
    getImages()
  }, [])

  useEffect(() => {
    if (
      options.sizes.length > 0 &&
      options.colors.length > 0 &&
      options.styles.length > 0 &&
      state?.styleId &&
      state?.sizeId &&
      state?.colorId
    ) {
      const findSize = options.sizes[0].options.find(
        x => x.value === parseInt(state?.sizeId)
      )
      const findColor = options.colors[0].options.find(
        x => x.value === parseInt(state?.colorId)
      )
      const findStyle = options.styles[0].options.find(
        x => x.value === parseInt(state?.styleId)
      )
      setStyle(findStyle)
      setColor(findColor)
      form.setFieldsValue({
        price: state?.price + " $",
        size: findSize.label,
        type: findStyle.label,
        color: findColor.label,
        notes: state?.note,
      })
    }
  }, [state?.styleId, state?.sizeId, state?.colorId, options])

  const handleAcceptedFiles = (files, position) => {
    setLoading(true)
    const arr = []
    if (files.length > 0) {
      ;[...files].map(item => {
        item.url = URL.createObjectURL(item)
      })

      selectedFiles.length > 0 &&
        selectedFiles.map((item, index) => {
          item.position !== position
            ? arr.push({
                position: item.position,
                images: [...item.images],
              })
            : delete item[index]
        })
      arr.push({
        position: position,
        images: [...files],
      })
    }
    setselectedFiles(arr)

    setLoading(false)
  }

  const handleSave = () => {
    setLoading(true)
    const formValues = form.getFieldsValue()
    const error = []
    if (selectedMulti.length === 0) {
      error.push("position")
    }
    if (selectedFiles.length === 0) {
      error.push("icon")
    }
    setErroList(error)
    if (error.length === 0) {
      const formData = new FormData()
      formData.append("sku", state?.sku)
      formData.append("orderId", state?.orderNo)
      formData.append("hasPersonalization", state?.hasPersonalization)
      formData.append("Note", formValues.notes ? formValues.notes : "")
      selectedFiles.map((item, index) => {
        formData.append(`imageModels[${index}].positionCode`, item.position)

        item.images.map((x, i) => {
          formData.append(`imageModels[${index}].images`, x)
        })
      })

      Http.post_form_data("api/orders/UploadImageToOrder", formData)
        .then(res => {
          if (res.success) {
            toastr.success(
              `Image upload for sku number ${state?.sku} was successful.`
            )
            setTimeout(() => {
              toastr.info("Listeye yönlendiriliyorsunuz. Lütfen bekleyiniz.")
              setTimeout(() => {
                setLoading(false)
                navigate("/apparel-list")
              }, 500)
            }, 500)
          } else {
            setLoading(false)
            toastr.error(res?.Message)
          }
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error?.message)
        })
    } else {
      setLoading(false)
    }
  }
  const css = `
  .image_card_p0{
    padding:0!important
  }

  .image_card_p0 .ant-card-body{
    padding:0!important
  }

  .front{
    position:absolute;
    top:29%;
    left:29%;
    z-index:1;
    width:150px;
    height:150px;
    ${
      color?.hexCode === "#fff" ||
      color?.hexCode === "#ffffff" ||
      color?.hexCode === "#000" ||
      color?.hexCode === "#000000"
        ? "background-color:#e8e8e8ad"
        : ""
    }
  }

  .front.hoodie{
    top:22%;
  }

  .back{
    position:absolute;
    top:29%;
    left:29%;
    z-index:1;
    width:140px;
    height:140px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
  }

  .back.hoodie{
    top:27%;
  }

  .left{
    position:absolute;
    top:21%;
    left:47%;
    z-index:1;
    width:60px;
    height:60px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
  }
  
  .left.hoodie{
    top:17.5%;
    left:42%;
  }

  .right{
    position:absolute;
    top:21%;
    right:44.5%;
    z-index:1;
    width:60px;
    height:60px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
  }

  .right.hoodie{
    top:17.5%;
    right:42%;
  }

  .left_front{
    position:absolute;
    top:18%;
    right:25%;
    z-index:1;
    width:95px;
    height:95px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
  }

  .rigth_front{
    position:absolute;
    top:18%;
    left:25%;
    z-index:1;
    width:95px;
    height:95px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
  }
  
  .inside_right_sleeve,
  .outside_right_sleeve{
    position:absolute;
    bottom:16%;
    left:5%;
    width:35px;
    height:105px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
   }

  .inside_left_sleeve, .outside_left_sleeve{
    position:absolute;
    bottom:16%;
    right:4%;
    z-index:1;
    width:35px;
    height:105px;
     ${
       color?.hexCode === "#fff" ||
       color?.hexCode === "#ffffff" ||
       color?.hexCode === "#000" ||
       color?.hexCode === "#000000"
         ? "background-color:#e8e8e8ad"
         : ""
     }
  }`

  const onDelete = positionCode => {
    setLoading(true)
    let obj = {
      sku: state?.sku,
      orderId: state?.orderId,
      positions: [
        {
          positionCode: positionCode,
        },
      ],
    }
    Http.post(`api/orders/removeApparelImages`, obj)
      .then(res => {
        if (res?.success) {
          setRegisterePositions([])
          setLoading(false)
          getImages()
          toastr.success(`Process Success.`)

          let newPrice = parseInt(state?.price) - 1

          form.setFieldsValue({
            price: newPrice + " $",
          })
        } else {
          setLoading(false)
          toastr.error(res?.Message)
        }
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }
  return (
    <React.Fragment>
      <style>{css}</style>
      <Spin spinning={loading}>
        <div className="page-content">
          <Breadcrumbs
            title={
              <>
                Apparel Design Upload Page{" "}
                <span style={{ fontSize: 14 }}>
                  <span style={{ opacity: "0.3" }}>[ Order: </span>
                  {state?.orderNo}{" "}
                  <span style={{ opacity: "0.3" }}>| Sku: </span>
                  {state?.sku}
                  <span style={{ opacity: "0.3" }}> ]</span>
                </span>
              </>
            }
            button={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  columnGap: 15,
                }}
              >
                <Button
                  type="button"
                  className="btn btn_default d-flex align-items-center justify-content-center"
                  onClick={() => {
                    navigate(
                      state?.waitingList === "true"
                        ? "/apparel-waiting-for-desing"
                        : "/apparel-list"
                    )
                  }}
                  icon={
                    <i className="bx bx-arrow-back font-size-16 align-middle mr-2"></i>
                  }
                >
                  Return to List
                </Button>
                <Button
                  type="button"
                  className="btn btn-primary d-flex align-items-center justify-content-center mt-1"
                  style={{ maxWidth: 100 }}
                  onClick={() => {
                    setLoading(true)
                    setTimeout(() => {
                      form
                        .validateFields()
                        .then(() => {
                          handleSave()
                        })
                        .catch(err => {
                          setLoading(false)
                        })
                    }, 300)
                  }}
                >
                  {" "}
                  Design Save
                </Button>
              </div>
            }
          />
          <Card className="mt-3 mb-3">
            <style>{css}</style>
            {registeredPositions.length > 0 && (
              <>
                <Divider orientation="left">Registered positions: </Divider>
                <Row gutter={[16, 16]}>
                  {registeredPositions.map((item, index) => {
                    return (
                      <Col lg={6} key={item}>
                        <Tag
                          color="volcano"
                          style={{
                            color: "black",
                            padding: "5px 8px",
                            fontSize: "15px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {index + 1} -{" "}
                            {optionGroupSweat.find(x => x.value === item).label}
                          </span>

                          <Popconfirm
                            title="Attention!"
                            description={
                              <>
                                Are you sure you want to delete the design for
                                the <br />
                                <span style={{ fontWeight: "700" }}>
                                  {
                                    optionGroupSweat.find(x => x.value === item)
                                      .label
                                  }
                                </span>{" "}
                                of the Order?
                              </>
                            }
                            onConfirm={() => {
                              onDelete(
                                optionGroupSweat.find(x => x.value === item)
                                  .value
                              )
                            }}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              danger
                              icon={<i className="mdi mdi-delete" />}
                            />
                          </Popconfirm>
                        </Tag>
                      </Col>
                    )
                  })}
                </Row>
                <Divider />
              </>
            )}
            {controlArea ? (
              <Form form={form} layout="vertical">
                <Row gutter={[16, 0]}>
                  <Col lg={16}>
                    <Row gutter={[16, 0]}>
                      <Col lg={6}>
                        <Form.Item
                          label="Type"
                          name="type"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          label="Size"
                          name="size"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          label="Color"
                          name="color"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item
                          label="Price"
                          name="price"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                      <Col lg={24}>
                        <Form.Item
                          label="Icon Position"
                          name="position"
                          rules={[
                            {
                              required: true,
                              message: "This field is required!",
                            },
                          ]}
                        >
                          <Select
                            defaultValue={selectedMulti}
                            mode="tags"
                            tokenSeparators={[","]}
                            onChange={e => {
                              setBoxLoading(true)
                              setTimeout(() => {
                                setBoxLoading(false)
                              }, 750)
                              let newPrice = 0
                              if (registeredPositions.length > 0) {
                                newPrice =
                                  parseInt(state?.price) + parseInt(e.length)
                              } else {
                                newPrice =
                                  parseInt(state?.price) +
                                  parseInt(e.length - 1)
                              }
                              form.setFieldsValue({
                                price: newPrice + " $",
                              })
                              setErroList([])
                              setselectedMulti(e)
                            }}
                            options={
                              style?.label === "Sweatshirt"
                                ? registeredPositions.length > 0
                                  ? optionGroupSweat.filter(
                                      x =>
                                        !registeredPositions.includes(x.value)
                                    )
                                  : optionGroupSweat
                                : style?.label === "Onesie"
                                ? registeredPositions.length > 0
                                  ? optionGroupOnesie.filter(
                                      x =>
                                        !registeredPositions.includes(x.value)
                                    )
                                  : optionGroupOnesie
                                : registeredPositions.length > 0
                                ? optionGroup.filter(
                                    x => !registeredPositions.includes(x.value)
                                  )
                                : optionGroup
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={8}>
                    <Row gutter={[16, 0]}>
                      <Col lg={24}>
                        <Form.Item
                          name="notes"
                          label="Notes"
                          style={{ width: "100%" }}
                        >
                          <TextArea
                            rows={5}
                            disabled={state?.note && state?.note.length > 0}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>{" "}
              </Form>
            ) : (
              <Tag
                color="volcano"
                style={{
                  width: "100%",
                  padding: "10px 15px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    fontSize: 15,
                  }}
                >
                  Tüm Pozisyonlar için tasarım yükleme işlemi gerçekleştirilmiş.
                  Eğer yükleme yapmak istiyorsanız önce yükleme yaapcağınız
                  posizyon için silme işlemi gerçekleştiriniz.
                </span>
              </Tag>
            )}
          </Card>
          {selectedMulti.length > 0 && (
            <Row gutter={[16, 0]}>
              {color &&
                selectedMulti.map((item, index) => {
                  const findValue = selectedFiles?.find(
                    x => x.position === item
                  )
                  const findText = optionGroupSweat?.find(x => x.value === item)
                  const val = imageValue.find(x => x.position === item)
                  let imageUrl = null
                  if (item === "left") {
                    imageUrl =
                      color === "#000000"
                        ? style?.label === "Sweatshirt"
                          ? sweatshirtLeftWhite
                          : style?.label === "Hoodie"
                          ? hoodieLeftWhite
                          : teeLeftWhite
                        : style?.label === "Sweatshirt"
                        ? sweatshirtLeftBlack
                        : style?.label === "Hoodie"
                        ? hoodieLeftBlack
                        : teeLeftBlack
                  } else if (
                    item === "inside_right_sleeve" ||
                    item === "inside_left_sleeve"
                  ) {
                    imageUrl =
                      color === "#000000"
                        ? sweatshirtFrontWhite
                        : sweatshirtFrontBlack
                  } else if (
                    item === "outside_right_sleeve" ||
                    item === "outside_left_sleeve"
                  ) {
                    imageUrl =
                      color === "#000000"
                        ? sweatshirtBackWhite
                        : sweatshirtBackBlack
                  } else if (item === "right") {
                    imageUrl =
                      color === "#000000"
                        ? style?.label === "Sweatshirt"
                          ? sweatshirtRightWhite
                          : style?.label === "Hoodie"
                          ? hoodieRightWhite
                          : teeRightWhite
                        : style?.label === "Sweatshirt"
                        ? sweatshirtRightBlack
                        : style?.label === "Hoodie"
                        ? hoodieRightBlack
                        : teeRightBlack
                  } else if (item === "back") {
                    imageUrl =
                      color === "#000000"
                        ? style?.label === "Sweatshirt"
                          ? sweatshirtBackWhite
                          : style?.label === "Hoodie"
                          ? hoodieBackWhite
                          : style?.label === "Onesie"
                          ? onesieBackwhite
                          : teeBackWhite
                        : style?.label === "Sweatshirt"
                        ? sweatshirtBackBlack
                        : style?.label === "Hoodie"
                        ? hoodieBackBlack
                        : style?.label === "Onesie"
                        ? onesieBackBlack
                        : teeBackBlack
                  } else {
                    imageUrl =
                      color === "#000000"
                        ? style?.label === "Sweatshirt"
                          ? sweatshirtFrontWhite
                          : style?.label === "Hoodie"
                          ? hoodieFrontWhite
                          : style?.label === "Onesie"
                          ? onesieFrontwhite
                          : teeFrontWhite
                        : style?.label === "Sweatshirt"
                        ? sweatshirtFrontBlack
                        : style?.label === "Hoodie"
                        ? hoodieFrontBlack
                        : style?.label === "Onesie"
                        ? onesieFrontBlack
                        : teeFrontBlack
                  }
                  return (
                    <Col lg={6} key={index + "-col"}>
                      <Card
                        hoverable
                        key={index + "-file"}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "10px",
                          justifyContent: "center",
                        }}
                        className="image_card_p0 mb-3"
                      >
                        <Spin spinning={boxLoading}>
                          <div style={{ background: color.hexCode }}>
                            <ImageComponent
                              key={`tshirt_${index}]`}
                              imageUrl={imageUrl}
                              width={350}
                              height={350}
                              preview={false}
                            />
                          </div>

                          {color &&
                            selectedMulti &&
                            selectedFiles.length > 0 && (
                              <div
                                className={
                                  item +
                                  " " +
                                  style.label.toLowerCase() +
                                  " designApparel"
                                }
                                key={`div_${index}]`}
                                style={{
                                  backgroundImage: `url(${
                                    findValue?.images[val?.img]?.url
                                  })`,
                                  border: "red 1px dashed",
                                  backgroundSize: "contain",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "bottom",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              />
                            )}
                        </Spin>
                        <div className="mb-3" style={{ width: "100%" }}>
                          <Label
                            className="form-label mt-1"
                            style={{ fontWeight: 600 }}
                          >
                            {findText?.label} - Icon
                          </Label>
                          <input
                            type="file"
                            accept="image/*"
                            className="form-control"
                            onChange={e => {
                              setErroList([])
                              handleAcceptedFiles(e.target.files, item)
                            }}
                          />
                          <FormFeedback
                            type="valid"
                            style={
                              erroList.find(x => x === "icon")
                                ? { display: "block" }
                                : {}
                            }
                          >
                            This field is required.
                          </FormFeedback>
                        </div>
                      </Card>
                    </Col>
                  )
                })}
            </Row>
          )}
        </div>
      </Spin>
    </React.Fragment>
  )
}

export default ApparelDesignUpload
