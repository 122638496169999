import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "reactstrap"
import UpsertModal from "../Modals/upsert"
import toastr from "toastr"
import * as moment from "moment"
import * as Http from "utils/http.helper"
import OrderDetails from "../Modals/order_details"
import config from "config"
import {
  Table,
  Tag,
  Button,
  Select,
  Tooltip,
  Card,
  Form,
  Typography,
  Input,
} from "antd"
import {
  SearchOutlined,
  ClearOutlined,
  EyeOutlined,
  AppstoreAddOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  UploadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons"
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom"
const { Option } = Select
const { Paragraph } = Typography

import "toastr/build/toastr.min.css"

const css = `
#order-list td{
  vertical-align: middle;
}
tr{ cursor:pointer }
 .multipleRow, .multipleRow .ant-table-cell-fix-right{
  background:#c2dafe!important;
}
.multipleRow td:first-child
{
  font-weight:bold
}
.ant-table-row-level-1 .ant-table-cell:first-child,
.ant-table-row-level-2 .ant-table-celll:first-child,
.ant-table-row-level-3 .ant-table-celll:first-child,
.ant-table-row-level-4 .ant-table-celll:first-child,
.ant-table-row-level-5 .ant-table-celll:first-child,
.ant-table-row-level-6 .ant-table-celll:first-child,
.ant-table-row-level-7 .ant-table-celll:first-child,
.ant-table-row-level-8 .ant-table-celll:first-child,
.ant-table-row-level-9 .ant-table-celll:first-child,
.ant-table-row-level-10 .ant-table-celll:first-child{
  font-weight:bold
}
.ant-table-cell-row-hover,
.ant-table-cell-row-hover.ant-table-cell-fix-right{
  background:#dddddd!important;
}
.ant-table-cell.ant-table-cell-with-append .ant-table-row-expand-icon.ant-table-row-expand-icon-spaced{
  background:red
}
.multipleRow:hover .ant-table-cell-row-hover{
  background:#c2dafe!important;

}
@page  
{ 
    size: auto;  
    margin: 25mm 5mm 25mm 5mm;  
}`
const orderStatus = [
  {
    value: 10,
    label: "New Order",
  },
  {
    value: 20,
    label: "Production Ready",
  },
  {
    value: 30,
    label: "Production",
  },
  {
    value: 40,
    label: "Downloaded",
  },
  {
    value: 50,
    label: "Completed",
  },
  {
    value: 60,
    label: "Shipped",
  },
  {
    value: 70,
    label: "Canceled",
  },
]
const OrderList = () => {
  document.title = "Apparel List | EASY JET CONNECT"
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state
  const [form] = Form.useForm()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [companyDataList, setCompanyDataList] = useState([])
  const [recordNameList, setRecordNameList] = useState([])
  const [showRecordName, setShowRecordName] = useState("")
  const [maxDate, setMaxDate] = useState(null)
  const [orderDetails, setOrderDetails] = useState({
    show: false,
    data: null,
  })
  const [upsertModal, setUpsertModal] = useState({ show: false, data: null })
  const [tableParams, setTableParams] = useState({
    current: 0,
    pageSize: 0,
    total: 0,
  })

  useEffect(() => {
    if (state?.orderId) {
      form.setFieldsValue({
        orderId: state?.orderId,
      })
    }
  }, [])

  const getTableData = (pagination, recName) => {
    if (form.validateFields()) {
      setLoading(true)
      const formValues = form.getFieldValue()
      let findRecord = recordNameList.find(
        x => x.value === formValues.recordName
      )
      if (findRecord || recName) {
        setProducts([])
        Http.get(
          `api/orders/getorders?PageNumber=${
            pagination?.current ? pagination.current : 1
          }&Limit=${
            pagination?.pageSize ? pagination.pageSize : 10
          }&categoryId=1&OrderRecordName=${
            findRecord ? findRecord.label : recName
          }${formValues.orderId ? "&search=" + formValues.orderId : ""}`
        )
          .then(res => {
            if (res?.success) {
              if (res?.data?.data?.orders.length > 0) {
                let maxDate = null
                res?.data?.data?.orders.map(item => {
                  item.key = item.id
                  item?.children?.length === 0 && delete item.children
                  const addDate = new Date(item.addDate)
                  if (maxDate === null || addDate > maxDate) {
                    maxDate = addDate
                  }
                })
                let newMaxDate = new Date(maxDate)
                // Saati, dakikayı ve saniyeyi sıfırlıyoruz
                setMaxDate(moment(new Date(newMaxDate)).format("DD/MMM/YYYY"))

                setProducts(res?.data?.data?.orders)
                setTableParams({
                  current: pagination?.current ? pagination.current : 1,
                  pageSize: pagination?.pageSize ? pagination.pageSize : 10,
                  total:
                    res?.data?.totalItems !== 0
                      ? res?.data?.totalItems
                      : tableParams.total,
                })
                setLoading(false)
              } else {
                setLoading(false)
                setProducts([])
              }
            } else {
              setProducts([])
              setTableParams({
                current: 1,
                pageSize: 10,
                total: 0,
              })
            }
            setLoading(false)
          })
          .catch(function (error) {
            setLoading(false)
            toastr.error(error.response.Message)
          })
      } else {
        setLoading(false)
      }
    }
  }

  const getCompanyData = () => {
    Http.get("api/companies/getAllCustomers?categoryId=1")
      .then(res => {
        if (res?.success) {
          let arr = [{ label: "All", value: "" }]
          res?.data.map(item => {
            arr.push({ label: item.name, value: item.id })
          })
          setCompanyDataList(arr)
          if (!state?.orderId) {
            form.setFieldsValue({
              companyId: "",
            })
            getRecordsName("")
          }
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("authUserV0")).roleId === 1 ||
      JSON.parse(localStorage.getItem("authUserV0")).roleId === 7
    ) {
      getCompanyData()
    }
  }, [])

  const getRecordsName = val => {
    Http.get(`api/orders/GetRecordNames?companyId=${val}&categoryId=1`)
      .then(res => {
        if (res.data.length > 0) {
          setRecordNameList(
            res?.data.map(item => {
              return { label: item.name, value: item.id }
            })
          )
          if (
            JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 ||
            JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7 ||
            JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1
          ) {
            if (!state?.orderId) {
              form.setFieldsValue({
                recordName: res.data[0].id,
              })
              setShowRecordName(res.data[0].name)
              getTableData(null, res?.data[0].name)
            }
          }
        } else {
          setRecordNameList([])
          form.setFieldsValue({
            recordName: "",
          })
        }
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("authUserV0"))?.roleId !== 1 &&
      JSON.parse(localStorage.getItem("authUserV0"))?.companyId
    ) {
      getRecordsName(JSON.parse(localStorage.getItem("authUserV0"))?.companyId)
    }
  }, [])

  const handleChangeStatus = (id, statusCode, itemName) => {
    setLoading(true)
    Http.post("api/orders/ChangeOrderStatus", {
      orderId: id,
      status: statusCode,
    })
      .then(res => {
        if (res.success) {
          toastr.success(`${itemName} sku, order status has been update.`)
        } else {
          setProducts([])
          getTableData({
            current: tableParams.current,
            pageSize: tableParams.pageSize,
          })
          toastr.error(`${itemName} sku, order status update process failed`)
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        setProducts([])
        getTableData({
          current: tableParams.current,
          pageSize: tableParams.pageSize,
        })
        toastr.error("Status update process fail")
      })
  }

  const handleTableChange = pagination => {
    setLoading(true)
    setTableParams({
      current: pagination.current,
      pageSize: pagination.pageSize,
      total: tableParams.total,
    })
    if (
      tableParams.current !== pagination.current ||
      tableParams.pageSize !== pagination.pageSize
    ) {
      getTableData({
        current: pagination.current,
        pageSize: pagination.pageSize,
      })
    }
  }

  const columns = [
    {
      title: "Sku",
      dataIndex: "sku",
      key: "sku",
      width: 150,
      render: value => {
        return <Paragraph copyable>{value}</Paragraph>
      },
    },
    {
      title: "Order No",
      dataIndex: "orderId",
      key: "orderId",
      render: value => {
        return <Paragraph copyable>{value}</Paragraph>
      },
    },
    {
      title: "Quant.",
      dataIndex: "quantity",
      key: 3,
      width: 80,
    },
    {
      title: "Style",
      dataIndex: "style",
      key: 4,
    },
    {
      title: "Size",
      dataIndex: "size",
      key: 5,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: 6,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: 7,
      render: (_, row) => {
        return row.price ? row.price + " $" : ""
      },
    },
    {
      title: "Personalization",
      dataIndex: "personalization",
      key: 8,
      width: 130,
      render: (_, row) => {
        if (row.hasPersonalization) {
          return (
            <Tooltip placement="top" title={row.personalization}>
              <div className="tooltip_table">
                {row?.personalization.length > 12
                  ? `${row?.personalization.substring(0, 12)}...`
                  : row?.personalization}
              </div>
            </Tooltip>
          )
        } else {
          return ""
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: 9,
      width: "150px",
      render: (_, row) => {
        if (
          JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 &&
          row.status < 30
        ) {
          return (
            <Select
              style={{
                width: "100%",
              }}
              defaultValue={row?.status}
              disabled={!row?.hasImages || row.status > 20}
              onChange={e => handleChangeStatus(row.id, e, row.sku)}
            >
              {orderStatus.map((item, index) => {
                if (item.value < row.status + 20 && item.value < 30) {
                  return (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  )
                }
              })}
            </Select>
          )
        } else {
          return (
            <Tag
              color={
                row.status === 10
                  ? "#28A745"
                  : row.status === 20
                  ? "#FFC107"
                  : row.status === 30
                  ? "#007BFF"
                  : row.status === 40
                  ? "#6F42C1"
                  : row.status === 50
                  ? "#6C757D"
                  : "#17A2B8"
              }
              key={row.id}
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              {orderStatus.find(x => x.value === row.status)?.label}
            </Tag>
          )
        }
      },
    },
    {
      title: "Name Surname",
      dataIndex: "nameSurname",
      key: 10,
      render: (_, row) => {
        return row.shipmentDetail.nameSurname
      },
    },
    {
      title: "Sale Date",
      dataIndex: "saleDate",
      key: "saleDate",
      key: 9,
      render: value => {
        return moment(new Date(value)).format("DD MMM YYYY")
      },
    },
    {
      title: "Has Image",
      dataIndex: "hasImages",
      key: "hasImages",
      align: "center",
      render: value => {
        return value ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
        )
      },
    },
    {
      title: " ",
      key: "action",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7,
      width:
        JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 ? 100 : 80,
      render: (_, record) => {
        return (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ columnGap: 10 }}
          >
            <Tooltip placement="top" title="Show Design">
              <Button
                className="btn btn_success d-flex align-items-center justify-content-center"
                icon={<EyeOutlined />}
                disabled={!record.hasImages}
                onClick={() => setOrderDetails({ show: true, data: record })}
              />
            </Tooltip>

            {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2 && (
              <Tooltip placement="top" title="Upload Design">
                <Button
                  className="btn btn_tertiary d-flex align-items-center justify-content-center"
                  icon={<UploadOutlined />}
                  disabled={record.status > 20}
                  onClick={() => {
                    navigate(`/apparel-design-upload`, {
                      state: record,
                    })
                  }}
                />
              </Tooltip>
            )}

            {/* {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 ||
              JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2) && (
              <Tooltip placement="top" title="Move Waiting For Design">
                <Popconfirm
                  title="Attention!"
                  description={
                    <>
                      Are you sure you want to move order number <br />{" "}
                      <span style={{ fontWeight: "700" }}>
                        {record.orderId}
                      </span>{" "}
                      to the design waiting list?
                    </>
                  }
                  onConfirm={() => {
                    onMoveWaitingList(record)
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    type="button"
                    disabled={record.hasImages}
                    className="btn btn_quinary d-flex align-items-center justify-content-center"
                    icon={<ClockCircleOutlined />}
                  />
                </Popconfirm>
              </Tooltip>
            )} */}
          </div>
        )
      },
    },
  ].filter(item => !item.hidden)

  const getImages = () => {
    setLoading(true)
    const values = form.getFieldsValue()
    const find = recordNameList.find(x => x.value === values.recordName)
    fetch(`${config.apiUrl}/api/orders/DownloadMultipleFiles`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        recordNameId: values.recordName,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.blob()
      })
      .then(blob => {
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = downloadUrl
        link.download = `${find.label}`
        document.body.appendChild(link)
        link.click()
        setLoading(false)
        getTableData(null)
        document.body.removeChild(link)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getAllImages = () => {
    setLoading(true)
    fetch(`${config.apiUrl}/api/orders/DownloadMultipleFiles`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        recordNameId: null,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.blob()
      })
      .then(blob => {
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = downloadUrl
        link.download = `All Company Images ${moment(new Date()).format(
          "DD.MM.YYYY HH.mm.ss"
        )}.zip`
        document.body.appendChild(link)
        link.click()
        setLoading(false)
        getTableData(null)
        document.body.removeChild(link)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const onMoveCompany = () => {
    const { recordName } = form.getFieldsValue()
    const findRecord = recordNameList.find(x => x.value === recordName)
    Http.get(
      `api/orders/SendForProduction?recordName=${findRecord.label}&categoryId=1`
    )
      .then(res => {
        if (res.success) {
          toastr.success(
            `${recordName} isimli kayıttaki siparişler üretime gönderilmiştir.`
          )
          getTableData({
            current: tableParams.current,
            pageSize: tableParams.pageSize,
          })
        } else {
          toastr.error(
            `${recordName} isimli kayıttaki siparişler üretime gönderilememiştir.`
          )
        }
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.response?.data?.Message)
      })
  }

  return (
    <React.Fragment>
      <style>{css}</style>

      {upsertModal.show && (
        <UpsertModal
          setUpsertModal={setUpsertModal}
          upsertModal={upsertModal}
          getTableData={getTableData}
          setLoading={setLoading}
          orderStatus={orderStatus}
          tableParams={tableParams}
        />
      )}

      {orderDetails.show && (
        <OrderDetails
          setModal={setOrderDetails}
          modal={orderDetails}
          getTableData={getTableData}
          setLoading={setLoading}
          loading={loading}
          tableParams={tableParams}
        />
      )}

      <div className="page-content mb-3">
        <Container fluid>
          <Card
            title="Apparel List"
            hoverable
            extra={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {/* company admin ve ya worker */}
                {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 ||
                JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 7 ? (
                  <Row>
                    <Col
                      span={24}
                      className="d-flex align-items-center justify-content-end"
                      style={{ columnGap: 15 }}
                    >
                      <Tooltip
                        placement="top"
                        title="Tüm Şirketlerin Tasarımları İndir"
                      >
                        <Button
                          type="button"
                          className="btn btn_tertiary d-flex align-items-center"
                          icon={<DownloadOutlined />}
                          onClick={() => {
                            getAllImages()
                          }}
                        >
                          Tüm Tasarımları İndir
                        </Button>
                      </Tooltip>
                      {showRecordName && (
                        <Button
                          type="button"
                          className="btn btn-primary d-flex align-items-center "
                          icon={<DownloadOutlined />}
                          onClick={() => {
                            getImages()
                          }}
                        >
                          {showRecordName} Tasarımlarını İndir
                        </Button>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col
                      span={24}
                      className="d-flex align-items-center justify-content-end gap-2"
                      style={{ columnGap: 15 }}
                    >
                      {products.filter(x => x.status === 10).length === 0 &&
                        JSON.parse(localStorage.getItem("authUserV0"))
                          ?.roleId === 1 && (
                          <Button
                            type="button"
                            className="btn btn-primary d-flex align-items-center"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                              getAllImages()
                            }}
                          >
                            Tüm Şirketlerin Tasarımları İndir
                          </Button>
                        )}
                      {products.filter(x => x.status === 10).length === 0 &&
                        JSON.parse(localStorage.getItem("authUserV0"))
                          ?.roleId === 1 &&
                        showRecordName && (
                          <Button
                            type="button"
                            className="btn btn-primary d-flex align-items-center"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                              getImages()
                            }}
                          >
                            {showRecordName} Tasarımlarını İndir
                          </Button>
                        )}
                      {/* <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center"
                        onClick={() => handlePrint}
                        icon={<FilePdfOutlined />}
                      >
                        Print PDF
                      </Button> */}
                      {JSON.parse(localStorage.getItem("authUserV0"))
                        ?.roleId === 2 && (
                        <Button
                          type="button"
                          className="btn btn-primary d-flex align-items-center"
                          onClick={() => {
                            navigate(`/apparel-add?maxDate=${maxDate}`)
                          }}
                          icon={<AppstoreAddOutlined />}
                        >
                          Add Order
                        </Button>
                      )}
                    </Col>
                  </Row>
                )}
              </div>
            }
          >
            <Form form={form} layout="vertical">
              <Row>
                <Col lg={2}>
                  <Form.Item label="Order No" name="orderId">
                    <Input onPressEnter={() => getTableData(null)} allowClear />
                  </Form.Item>
                </Col>
                {(JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                  1 ||
                  JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                    7) && (
                  <Col lg={2}>
                    <Form.Item label="Customer" name="companyId">
                      <Select
                        allowClear
                        options={companyDataList}
                        onChange={e => {
                          form.setFieldsValue({
                            orderId: "",
                          })
                          // setShowRecordName(res.data[0].name)
                          getRecordsName(e)
                        }}
                        disabled={companyDataList.length === 0}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col lg={2}>
                  <Form.Item
                    label="Record Name"
                    name="recordName"
                    rules={[
                      {
                        required: true,
                        message: "This field is required!",
                      },
                    ]}
                  >
                    <Select
                      options={recordNameList}
                      disabled={recordNameList.length === 0}
                      onChange={e => {
                        const find = recordNameList.find(x => x.value === e)
                        find && setShowRecordName(find.label)
                        getTableData(null)
                      }}
                    />
                  </Form.Item>
                </Col>
                {/* <Col lg={2}>
                  <Form.Item label="Barcode Tabancası Deneme" name="barcode">
                    <Input
                      ref={inputRef}
                      onPressEnter={e => handleInputChange(e)}
                      disabled={!recordName ? true : false}
                      onBlur={e => handleInputBlur(e)}
                      onKeyUp={e => handleInputChange(e)}
                    />
                  </Form.Item>
                </Col> */}
                <Col
                  span={3}
                  className="d-flex align-items-center justify-content-start gap-2"
                >
                  <Button
                    type="button"
                    className="btn btn-light d-flex align-items-center justify-content-start mt-1"
                    icon={<ClearOutlined />}
                    onClick={() => {
                      form.setFieldsValue({
                        orderId: "",
                        recordName: "",
                        companyId: "",
                      })
                      setProducts([])
                      JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                        1 && setRecordNameList([])
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="button"
                    className="btn btn-primary d-flex align-items-center justify-content-start mt-1"
                    icon={<SearchOutlined />}
                    onClick={() => {
                      getTableData(null)
                    }}
                  >
                    Search
                  </Button>
                </Col>
                <Col
                  span={6}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                    2 && (
                    <Button
                      type="button"
                      className="btn btn_quinary d-flex align-items-center"
                      onClick={() => onMoveCompany()}
                    >
                      Üretime Gönder
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
            <Table
              bordered
              size="small"
              columns={columns}
              dataSource={products}
              pagination={tableParams}
              loading={loading}
              onChange={handleTableChange}
              rowKey={record => record.id}
              rowClassName={record =>
                record.children?.length > 0 ? "multipleRow" : ""
              }
            />
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OrderList
