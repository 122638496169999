import React, { useState, useEffect } from "react"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  Button,
  Spin,
  DatePicker,
  Form,
  Select,
  Divider,
  Table,
  Input,
  Tooltip,
  Upload,
  Space,
  InputNumber,
} from "antd"
import {
  SearchOutlined,
  ClearOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons"
import Sounds from "utils/sounds"
import * as Http from "utils/http.helper"
import { useParams } from "react-router-dom"
import toastr from "toastr"
import * as moment from "moment"
import dayjs from "dayjs"
import config from "config"
const { RangePicker } = DatePicker
const { TextArea } = Input
const { Option } = Select
const paymentMethods = [
  {
    value: 10,
    label: "BankTransfer",
  },
  {
    value: 20,
    label: "CreditCard",
  },
  {
    value: 30,
    label: "DebitCard",
  },
  {
    value: 40,
    label: "Cash",
  },
  {
    value: 50,
    label: "Check",
  },
  {
    value: 60,
    label: "MobilePayment",
  },
  {
    value: 70,
    label: "OnlinePayment",
  },
  {
    value: 80,
    label: "Cryptocurrency",
  },
]
import "toastr/build/toastr.min.css"
const css = `
.ant-form-item.price{
  margin-bottom:0!important;
}
  .ant-upload.ant-upload-select{
  width:100%!important;
  }`
let apiToDateFormat = "MM-DD-YYYY"

const PaymentManagement = () => {
  document.title = `Payment Management | EASY JET CONNECT`
  const { categoryId } = useParams()
  const [saveForm] = Form.useForm()
  const [getForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [files, setFiles] = useState(null)
  const [data, setData] = useState([])
  const [totalPayment, setTotalPayment] = useState(0)
  const [update, setUpdate] = useState({ show: false, data: null })

  const getPaymentData = () => {
    const formValues = getForm.getFieldsValue()
    setLoading(true)
    if (JSON.parse(localStorage.getItem("authUserV0")).roleId === 1) {
      Http.get(
        `api/accounting/getCompanyPayment?categoryId=${categoryId}&customerId=${
          formValues?.customer_id ? formValues?.customer_id : ""
        }&startDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[0]).format(apiToDateFormat)
            : ""
        }&endDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[1]).format(apiToDateFormat)
            : ""
        }`
      )
        .then(res => {
          if (res?.success) {
            setData(res.data)
            let total = 0
            res.data.map(item => {
              total += item.paymentAmount
            })
            setTotalPayment(total)
          }
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error)
        })
    } else {
      Http.get(
        `api/accounting/GetCustomerPayment?categoryId=${categoryId}&startDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[0]).format(apiToDateFormat)
            : ""
        }&endDate=${
          formValues?.dates
            ? dayjs(formValues?.dates[1]).format(apiToDateFormat)
            : ""
        }`
      )
        .then(res => {
          if (res?.success) {
            setData(res.data)
            let total = 0
            res.data.map(item => {
              total += item.paymentAmount
            })
            setTotalPayment(total)
          }
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error)
        })
    }
  }

  useEffect(() => {
    setLoading(true)
    if (JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1) {
      Http.get(`api/companies/getAllCustomers?categoryId=${categoryId}`)
        .then(res => {
          if (res?.success) {
            setCustomers(
              res.data.map(item => {
                return { value: item.id, label: item.name }
              })
            )
            if (res.data.length === 1) {
              getForm.setFieldsValue({
                customer_id: res.data[0].id,
              })
            }
          }
          setLoading(false)
        })
        .catch(function (error) {
          setLoading(false)
          toastr.error(error)
        })
    }
    getPaymentData()
  }, [categoryId])

  const downloadPDF = record => {
    setLoading(true)
    fetch(`${config.apiUrl}/api/accounting/downloadReceipt?id=${record.id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok")
        }
        return response.blob()
      })
      .then(blob => {
        const downloadUrl = window.URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = downloadUrl
        link.download = `${record.customerName}_${moment
          .utc(record.paymentDate)
          .format("DD.MM.YYYY")}.pdf`
        document.body.appendChild(link)
        link.click()
        setLoading(false)
        getTableData(null)
        document.body.removeChild(link)
        toastr.success("indirme işlemi başarılı.")
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const columns = [
    {
      title: "Müşteri",
      dataIndex: "customerName",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2,
    },
    {
      title: "Ödeme Şekli",
      dataIndex: "paymentMethod",
      render: value => {
        return paymentMethods.find(x => x.value === value).label
      },
    },
    {
      title: "Ödeme Miktarı",
      dataIndex: "paymentAmount",
      render: value => {
        return "$" + value.toFixed(2)
      },
    },
    {
      title: "Ödeme Tarihi",
      dataIndex: "paymentDate",
      render: (_, row) => {
        return moment.utc(row.paymentDate).format("DD.MM.YYYY")
      },
    },
    {
      title: "Açıklama",
      dataIndex: "description",
    },
    {
      title: "Dekont?",
      key: "hasReceipt",
      dataIndex: "hasReceipt",
      align: "center",
      render: value => {
        return value ? (
          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
        )
      },
    },
    {
      title: "İşlemler",
      key: "action",
      hidden: JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 2,
      align: "center",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Tooltip placement="top" title="Güncelle">
              <Button
                type="button"
                className="btn btn_success d-flex align-items-center justify-content-center  m-auto"
                icon={<EditOutlined />}
                onClick={() => {
                  saveForm.setFieldsValue({
                    customerId: customers.find(
                      x => x.label === record?.customerName
                    )?.value,
                    paidAmount: record?.paymentAmount,
                    paidDate: new moment(record?.paymentDate),
                    paymentMethod: record?.paymentMethod,
                    description: record?.description,
                  })
                  setUpdate({ show: true, data: record })
                  Sounds.warning()
                  toastr.warning(
                    "Dekont güncelleme işleminde yeniden yüklenmelidir aksi taktirde eski dekont ile işlem yapılacaktır."
                  )
                }}
                style={{ marginRight: 5 }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Dekont İndir">
              <Button
                disabled={!record?.hasReceipt}
                type="button"
                className="btn btn_primary d-flex align-items-center justify-content-center"
                icon={<DownloadOutlined />}
                onClick={() => downloadPDF(record)}
              />
            </Tooltip>
          </Space>
        )
      },
    },
  ].filter(item => !item.hidden)

  const handleSavePayment = () => {
    const formData = new FormData()
    const formValues = saveForm.getFieldsValue()
    formData.append("customerId", formValues?.customerId)
    formData.append("categoryId", categoryId)
    formData.append("paidAmount", formValues?.paidAmount)
    formData.append(
      "paidDate",
      moment.utc(formValues?.paidDate).format("YYYY-MM-DD")
    )
    formData.append("paymentMethod", formValues?.paymentMethod)
    formData.append(
      "description",
      formValues.description ? formValues.description : ""
    )
    formData.append("receipt", files ? files : "")
    Http.post_form_data("api/accounting/amountPaidToTheCompany", formData)
      .then(res => {
        if (res.success) {
          toastr.success(`İşlem başarılı.`)
          getForm
            .validateFields()
            .then(() => {
              getPaymentData()
              saveForm.resetFields()
            })
            .catch(err => {
              setLoading(false)
            })
        }

        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.message)
      })
  }

  const handleUpdatePaymnet = () => {
    const formData = new FormData()
    const formValues = saveForm.getFieldsValue()
    formData.append("id", update.data.id)
    formData.append("customerId", formValues?.customerId)
    formData.append("paidAmount", formValues?.paidAmount)
    formData.append(
      "paidDate",
      moment.utc(formValues?.paidDate).format("YYYY-MM-DD")
    )
    formData.append("paymentMethod", formValues?.paymentMethod)
    formData.append(
      "description",
      formValues.description ? formValues.description : ""
    )
    formData.append("receipt", files ? files : "")
    Http.post_form_data("api/accounting/updatePaidToTheCompany", formData)
      .then(res => {
        if (res.success) {
          toastr.success(`İşlem başarılı.`)
          getForm
            .validateFields()
            .then(() => {
              getPaymentData()
              saveForm.resetFields()
              setUpdate({ show: false, data: null })
            })
            .catch(err => {
              setLoading(false)
            })
        }

        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)
        toastr.error(error?.message)
      })
  }

  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <style>{css}</style>
        <Spin spinning={loading}>
          <Breadcrumbs
            title={
              categoryId === "1"
                ? "Apparel Ödeme Yönetimi"
                : categoryId === "3"
                ? "Engraving Ödeme Yönetimi"
                : ""
            }
          />
          <Row gutter={[16, 16]}>
            <Col lg={8}>
              {JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1 && (
                <Card hoverable className="mt-3">
                  <Divider orientation="left">
                    {categoryId === "1"
                      ? "Apparel"
                      : categoryId === "3"
                      ? "Engraving"
                      : ""}{" "}
                    Ödeme Kaydı
                  </Divider>
                  <Form layout="vertical" form={saveForm}>
                    <Row gutter={[8, 0]}>
                      <Col lg={24}>
                        <Form.Item
                          label="Müşteri"
                          name="customerId"
                          rules={[
                            {
                              required: true,
                              message: "Customer  zorunludur.",
                            },
                          ]}
                        >
                          <Select allowClear>
                            {customers.map(item => (
                              <Option value={item.value} key={item.value}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          name="paymentMethod"
                          label="Ödeme Şekli"
                          rules={[
                            {
                              required: true,
                              message: "Ödeme şekli zorunludur!",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: "100%",
                            }}
                          >
                            {paymentMethods.map((item, index) => {
                              return (
                                <Option key={index} value={item.value}>
                                  {item.label}
                                </Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          name="paidAmount"
                          label="Ödeme miktarı"
                          rules={[
                            {
                              required: true,
                              message: "Ödeme miktarı zorunludur!",
                            },
                          ]}
                        >
                          <InputNumber min={0.1} style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          label="Ödeme Tarihi"
                          name="paidDate"
                          rules={[
                            {
                              required: true,
                              message: "Ödeme tarihi zorunludur!",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            format={"DD.MM.YYYY"}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={12}>
                        <Form.Item
                          name="receipt"
                          label="Dekont"
                          style={{ width: "100%" }}
                        >
                          <Upload
                            accept=".pdf"
                            maxCount={1}
                            beforeUpload={e => {
                              return false
                            }}
                            onChange={e => setFiles(e.file)}
                            className="upload-list-inline"
                          >
                            <Button
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                              }}
                              icon={<UploadOutlined />}
                            >
                              Lütfen Dekont Yükleyiniz!
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col lg={24}>
                        <Form.Item
                          name="description"
                          label="Açıklama"
                          style={{ width: "100%" }}
                        >
                          <TextArea />
                        </Form.Item>
                      </Col>
                      <Col
                        lg={24}
                        style={{
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "end",
                          columnGap: 10,
                        }}
                      >
                        {update?.show && (
                          <Button
                            type="button"
                            style={{ marginBottom: 24 }}
                            className="btn btn_danger d-flex align-items-center justify-content-center"
                            onClick={() => {
                              setUpdate(false)
                              saveForm.resetFields()
                            }}
                          >
                            Vazgeç
                          </Button>
                        )}
                        <Button
                          type="button"
                          block={!update?.show}
                          style={{ marginBottom: 24 }}
                          className="btn btn_success d-flex align-items-center justify-content-center"
                          onClick={() => {
                            setLoading(true)
                            saveForm
                              .validateFields()
                              .then(() => {
                                update.show
                                  ? handleUpdatePaymnet()
                                  : handleSavePayment()
                              })
                              .catch(err => {
                                setLoading(false)
                              })
                          }}
                        >
                          {update.show ? "Güncelle" : "Kaydet"}
                        </Button>{" "}
                      </Col>
                    </Row>
                  </Form>
                </Card>
              )}
            </Col>
            <Col
              lg={
                JSON.parse(localStorage.getItem("authUserV0"))?.roleId === 1
                  ? 16
                  : 24
              }
            >
              <Card hoverable className="mt-3">
                <Divider orientation="left">
                  {" "}
                  {categoryId === "1"
                    ? "Apparel"
                    : categoryId === "3"
                    ? "Engraving"
                    : ""}{" "}
                  Ödeme Listesi
                </Divider>
                <Form form={getForm} layout="vertical" className="mb-3">
                  <Row gutter={[16, 16]}>
                    {JSON.parse(localStorage.getItem("authUserV0"))?.roleId ===
                      1 && (
                      <Col lg={5}>
                        <Form.Item
                          label="Müşteri"
                          name="customer_id"
                          rules={[
                            {
                              required: true,
                              message: "Customer  zorunludur.",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Müşteri Seçiniz!"
                            options={customers}
                            style={{ minWidth: "150px" }}
                            onChange={() => getPaymentData()}
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>
                    )}

                    <Col lg={5}>
                      <Form.Item label="Başlangıç/Bitiş Tarihi" name="dates">
                        <RangePicker
                          style={{ width: "100%" }}
                          format={"DD/MM/YYYY"}
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      lg={5}
                      className="d-flex align-items-center justify-content-start gap-2"
                    >
                      <Button
                        type="button"
                        className="btn btn-light d-flex align-items-center justify-content-center"
                        icon={<ClearOutlined />}
                        style={{
                          border: "1px solid #f94040",
                          background: "#f94040",
                          color: "white",
                          width: "100%",
                        }}
                        onClick={() => {
                          getForm.resetFields()
                          getPaymentData()
                        }}
                      >
                        Temizle
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary d-flex align-items-center justify-content-center "
                        style={{
                          border: "1px solid #556ee6",
                          width: "100%",
                        }}
                        icon={<SearchOutlined />}
                        onClick={() => {
                          setLoading(true)
                          getForm
                            .validateFields()
                            .then(() => {
                              getPaymentData()
                            })
                            .catch(err => {
                              setLoading(false)
                            })
                        }}
                      >
                        Ara
                      </Button>
                    </Col>
                    <Col
                      lg={
                        JSON.parse(localStorage.getItem("authUserV0"))
                          ?.roleId === 1
                          ? 9
                          : 14
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        fontSize: 18,
                      }}
                    >
                      * Toplam Ödeme:{" "}
                      <span
                        style={{ color: "red", fontSize: 18, marginLeft: 10 }}
                      >
                        ${totalPayment.toFixed(2)}
                      </span>{" "}
                    </Col>
                  </Row>
                </Form>
                <Table
                  bordered
                  size="small"
                  columns={columns}
                  dataSource={data}
                  loading={loading}
                  rowKey={record => record.id}
                />
              </Card>
            </Col>
          </Row>
        </Spin>
      </div>
    </React.Fragment>
  )
}

export default PaymentManagement
