import React, { useState, useEffect, useRef } from "react"
import { Form, Spin, Input, Row, Col, Card, Descriptions, Divider } from "antd"
import * as Http from "utils/http.helper"
import Breadcrumbs from "components/Common/Breadcrumb"
import ImageComponent from "components/image"
import config from "config"
import error from "assets/images/error-img.png"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import SVGFromURL from "components/svg2comp"
const allPositions = [
  {
    name: "Front Center",
    code: "front_center",
  },
  {
    name: "Spine",
    code: "spine",
  },
  {
    name: "Front Vertical on the Right",
    code: "front_vertical_on_the_right",
  },
  {
    name: "Front Left Lower Corner",
    code: "front_left_lower_corner",
  },
  {
    name: "Front Bottom Center",
    code: "front_bottom_center",
  },
  {
    name: "Inside Center",
    code: "inside_center",
  },
  {
    name: "Inside Upper Side",
    code: "inside_upper_side",
  },
  {
    name: "Inside Left Lower Corner",
    code: "inside_left_lower_corner",
  },
  {
    name: "Inside Left Side",
    code: "inside_left_side",
  },
  {
    name: "Inside Right Side",
    code: "inside_right_side",
  },
  {
    name: "Back Center",
    code: "back_center",
  },
  {
    name: "Back Right Lower Corner",
    code: "back_right_lower_corner",
  },
  {
    name: "Back Bottom Center",
    code: "back_bottom_center",
  },
  {
    name: "Stones",
    code: "stones",
  },
  {
    name: "Tools",
    code: "tools",
  },
  {
    name: "Guitar Pick",
    code: "guitar_pick",
  },
  {
    name: "Front Right Lower Corner",
    code: "front_right_lower_corner",
  },
]

const EngravingPrinter = () => {
  //meta title
  document.title = "Engraving Printer Page | EASY JET CONNECT"
  const [form] = Form.useForm()
  const inputRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState([])
  const [orderNo, setOrderNo] = useState(null)
  const [productNameList, setProductNameList] = useState([])

  useEffect(() => {
    inputRef.current && inputRef.current.focus()
  }, [])

  const handleInputChange = e => {
    if (e.key === "Enter" && e.target.value.length > 0) {
      getOrder(e.target.value)
      setOrderNo(e.target.value)
      setTimeout(() => {
        inputRef.current.focus()
      }, 500)
    }
  }

  const handleInputBlur = e => {
    if (e.target.value.length > 0) {
      getOrder(e.target.value)
      setOrderNo(e.target.value)
    }
    setTimeout(() => {
      inputRef.current && inputRef.current.focus()
    }, 750)
  }

  const getOrder = value => {
    const orderStatus =
      JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0
        ? JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length > 0 &&
          JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
            "Completed"
          ? 50
          : 60
        : 0
    form.setFieldsValue({
      barcode: "",
    })
    setLoading(true)
    Http.get(
      `api/orders/GetEngravingOrderImagesByOrderId?orderId=${value}&status=${orderStatus}`
    )
      .then(response => {
        if (response?.success) {
          const res = response.data
          let imagesList = []
          let productNameList = []
          if (res?.templates && res?.orderImages) {
            res.orderImages.map(item => {
              let findName = productNameList.find(x => x === item.productName)
              !findName && productNameList.push(item.productName)
              item.positions.length > 0 &&
                item.positions.map(rq => {
                  let findTemplate = res.templates.find(
                    x => x.positionIds[0] === rq.positionId
                  )
                  let position = allPositions.find(
                    x => x.code === rq.positionCode
                  )
                  let newVariation = []
                  item.variation.split(",").map(rq => {
                    newVariation.push(rq)
                  })
                  if (findTemplate) {
                    imagesList.push({
                      img: rq.imgUrl,
                      position: position?.name,
                      color: findTemplate?.colorHexCode,
                      tempImg: findTemplate?.imageUrl,
                      type: findTemplate?.type,
                      productName: item.productName,
                      quantity: item.quantity,
                      sku: item.sku,
                      variation: newVariation,
                      note: item.note,
                    })
                  }
                })
            })
          }
          if (imagesList.length > 0) {
            const groupedProducts = imagesList.reduce((acc, product) => {
              const { productName } = product
              if (!acc[productName]) {
                acc[productName] = []
              }
              acc[productName].push(product)
              return acc
            }, {})

            setInfo(groupedProducts)
            setProductNameList(productNameList)
            JSON.parse(localStorage.getItem("authUserV0"))?.actions?.length >
              0 &&
              JSON.parse(localStorage.getItem("authUserV0"))?.actions[0] ===
                "Completed" &&
              getDesignById(value)
          } else {
            setInfo([])
          }
        } else {
          toastr.error(
            `${value} numaralı order henüz print etmek için hazır olmayabilir (Has Image=false) ve ya order numaranız hatalı olabilir. Kontrol ediniz!`
          )
          setInfo([])
        }
        setLoading(false)
      })
      .catch(function (error) {
        setInfo([])
        toastr.error(error?.response?.data?.Message)
        setLoading(false)
      })
  }

  const getDesignById = value => {
    setLoading(true)
    try {
      fetch(`${config.apiUrl}/api/orders/DownloadEngravingFiles`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          orderId: value,
        }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Network response was not ok")
          }
          return response.blob()
        })
        .then(blob => {
          const downloadUrl = window.URL.createObjectURL(blob)
          const link = document.createElement("a")
          link.href = downloadUrl
          link.download = `${value}`
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
        })
    } catch (error) {}
  }
  return (
    <React.Fragment>
      <div className="page-content mb-3">
        <Spin spinning={loading}>
          <Breadcrumbs title=" Engraving Printer" />
          <Card className="mt-3" hoverable>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col lg={8}>
                  <Form.Item label="Order Number" name="barcode">
                    <Input
                      ref={inputRef}
                      onPressEnter={e => handleInputChange(e)}
                      onBlur={e => handleInputBlur(e)}
                      onKeyUp={e => handleInputChange(e)}
                      onChange={e => handleInputChange(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {orderNo && (
              <Divider orientation="left">
                <span
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Order No: {orderNo}
                </span>
              </Divider>
            )}
            {productNameList.length > 0 &&
              productNameList.map((x, y) => {
                return (
                  <Card
                    hoverable
                    key={x + "_" + y}
                    className="mt-3"
                    bordered
                    title={<span style={{ fontWeight: "bold" }}>{x}</span>}
                  >
                    <Row gutter={[16, 16]}>
                      <Col lg={24}>
                        <Descriptions
                          bordered
                          layout="vertical"
                          className="mb-3"
                          column={{
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3,
                          }}
                        >
                          <Descriptions.Item label="Sku">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {info[`${x}`][0].sku}
                            </span>
                          </Descriptions.Item>
                          <Descriptions.Item label="Product Name">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {info[`${x}`][0].productName}
                            </span>
                          </Descriptions.Item>
                          <Descriptions.Item label="Quantity">
                            <span
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {info[`${x}`][0].quantity}
                            </span>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Color"
                            style={{ width: 250 }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                columnGap: 20,
                              }}
                            >
                              <div
                                style={{
                                  color: info[`${x}`][0].color,
                                  fontWeight: "bold",
                                }}
                              >
                                {info[`${x}`][0].color}
                              </div>
                              <div
                                style={{
                                  backgroundColor: info[`${x}`][0].color,
                                  height: 30,
                                  width: 30,
                                  borderRadius: "100%",
                                  boxShadow: "#0000002e 0px 0px 10px 2px",
                                }}
                              ></div>
                            </div>
                          </Descriptions.Item>
                          <Descriptions.Item
                            label="Variation"
                            style={{
                              textAlign: "left",
                              columnGap: 10,
                            }}
                          >
                            <ul
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "left",
                                columnGap: "50px",
                                fontWeight: "bold",
                              }}
                            >
                              {info[`${x}`][0].variation &&
                                info[`${x}`][0].variation.length > 0 &&
                                info[`${x}`][0].variation.map((item, index) => {
                                  return (
                                    <li className="list-item" key={index}>
                                      {item}
                                    </li>
                                  )
                                })}
                            </ul>{" "}
                          </Descriptions.Item>
                        </Descriptions>
                        <Row gutter={[16, 16]}>
                          {info[x].map((item, index) => {
                            return (
                              <Col lg={8} key={y + "_" + index}>
                                <Descriptions
                                  title={
                                    <div
                                      style={{
                                        fontWeight: "700",
                                      }}
                                    >
                                      * {item.position}
                                    </div>
                                  }
                                  bordered
                                  layout="vertical"
                                  className="mb-3"
                                >
                                  <Descriptions.Item
                                    label="Template"
                                    style={{ textAlign: "center" }}
                                  >
                                    <style>
                                      {`g g polyline, g g line{ stroke: blue!important }
                                  rect, path { fill: ${item.color}!important }
                                  `}
                                    </style>

                                    <SVGFromURL
                                      url={item.tempImg}
                                      width={120}
                                      height={120}
                                    />
                                  </Descriptions.Item>
                                  <Descriptions.Item
                                    label="Design"
                                    style={{ textAlign: "center" }}
                                  >
                                    <ImageComponent
                                      imageUrl={item.img}
                                      width={120}
                                      height={120}
                                    />
                                  </Descriptions.Item>
                                </Descriptions>
                              </Col>
                            )
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                )
              })}

            {info.length === 0 && (
              <>
                <Row gutter={[16, 16]}>
                  {" "}
                  <Col lg={8}>
                    <div className="text-center mb-5">
                      <h5 className="text-uppercase">
                        Lütfen Order Numarası İle arama yapınız!
                      </h5>
                    </div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]} className="justify-content-center">
                  <Col lg={12}>
                    <div>
                      <img src={error} alt="" className="img-fluid" />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </Spin>
      </div>{" "}
    </React.Fragment>
  )
}

export default EngravingPrinter
